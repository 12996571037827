import React from 'react'
import { Link } from 'react-scroll'
import { FormattedMessage } from 'react-intl'
import Button from '@zcool/button'

export default function Hint() {
  return (
    <Link to="apply-form" duration={300} offset={-80} smooth={true}>
      <Button type="primary" size="large">
        <FormattedMessage id="actions.apply">{(msg) => msg}</FormattedMessage>
      </Button>
    </Link>
  )
}
