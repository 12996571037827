import styled from 'styled-components'
import { palette, font } from '@zcool/theme'

interface Props {
  url?: string
}

const Background = styled.main<Props>`
  .background {
    background-image: url(${(props) => props.url});
    position: relative;
    background-size: cover;
    background-position: 50%;
    height: 700px;
    text-align: center;
    color: white;
    font-size: 32px;
    overflow: hidden;

    .hero-content {
      text-align: center;
      color: ${palette.white};
      z-index: 1;

      h1 {
        font-size: 44px;
        line-height: 44px;
        margin-bottom: 24px;
      }

      p {
        max-width: 464px;
        margin: auto;
        font-size: 16px;
        text-align: center;
        line-height: 24px;
      }

      button {
        margin-top: 24px;
        border-radius: 4px;
        padding: 0 32px;
        max-width: none;
      }

      .apply-hint {
        margin-top: 36px;
        font-size: 18px;
        font-weight: bold;
        background: ${palette.black3};
        display: inline-block;
      }
    }

    .image-info {
      position: absolute;
      bottom: 24px;
      right: 32px;
      opacity: 0.5;
      font-size: 12px;
      color: ${palette.white};
    }
  }

  .content-tabs {
    border: none;
    align-items: flex-end;
    height: 64px;
    font-weight: ${font.weight.normal};

    > span {
      margin: 0 35px;

      &[data-selected='true'] {
        color: ${palette.primary};
        font-weight: ${font.weight.bold};
      }

      &:hover {
        font-weight: ${font.weight.medium};
        color: ${palette.spruce};
      }
    }
  }

  .content-section {
    background: ${palette.pearl};

    & > span {
      font-weight: 300;
    }
  }

  .content-list {
    max-width: 1280px;

    &.font {
      width: 1104px;
    }

    margin: 0 auto;
    margin-bottom: 40px;
  }

  /* 首页相关 */
`

export default Background

export const WelComeBackground = styled(Background)`
  .welcome-options {
    text-align: center;
    margin-top: 48px;

    .item {
      display: inline-block;
      margin: 0 58px;

      p {
        margin-top: 16px;
      }
    }
  }

  .pl64 {
    padding-left: 64px;
  }

  .welcome-section {
    width: 1000px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    > img,
    .welcome-section__text .feature {
      position: relative;
      z-index: 2;
    }

    &.last {
      margin-bottom: 80px;
      padding-bottom: 0;

      .welcome-section__text {
        width: 390px;
        left: -24px;
        position: initial;
      }
    }

    .welcome-section__text {
      min-width: 390px;
      display: inline-block;
      text-align: left;
      position: relative;

      .index {
        margin-top: 20px;
        display: inline-block;
        opacity: 0.5;
        font-size: 88px;
        font-weight: ${font.weight.bold};
        color: ${palette.pearl};
        text-align: left;
      }

      .title {
        margin-top: -32px;
        font-size: 22px;
        color: ${palette.black3};
        line-height: 24px;
        margin-bottom: 24px;
        position: relative;
      }

      .title::after {
        content: '';
        top: calc(100% + 12px);
        left: -12px;
        position: absolute;
        width: 40px;
        height: 1px;
        background: ${palette.primary};
      }

      .description {
        text-align: left;
        line-height: 1.5;
        font-size: 16px;
        color: ${palette.spruce};
      }

      .feature {
        margin-top: 72px;
        line-height: 2.5;

        > p {
          margin-left: 8px;
          color: ${palette.black3};
          font-size: 14px;
          position: relative;
        }

        > p::before {
          content: '';
          position: absolute;
          left: -28px;
          top: 8px;
          width: 14px;
          height: 10px;
          border: 3px solid ${palette.primary};
          border-top: none;
          border-right: none;
          background: transparent;
          transform: rotate(-45deg);
        }
      }
    }

    .bg1,
    .bg2,
    .bg3,
    .bg4 {
      background: ${palette.pearl};
      position: absolute;
    }

    .bg1 {
      width: 620px;
      height: 220px;
      left: 0;

      &.en {
        top: 212px;
      }

      &.zh {
        top: 190px;
      }
    }

    .bg2 {
      width: 630px;
      height: 162px;
      top: 190px;
      left: -240px;
    }

    .bg3 {
      width: 674px;
      height: 148px;
      top: 190px;
      left: 0;
    }

    .bg4 {
      width: 98vw;
      height: 344px;
      top: 270px;
      transform: translateX(-50%);
      left: 50%;
      z-index: -1;
    }
  }

  .welcome-card {
    text-align: center;

    .welcome-card__item {
      margin: 0 5px;
      display: inline-block;
      position: relative;

      img {
        width: 192px;
        height: 245px;
      }

      span {
        color: ${palette.white};
        font-size: 12px;
        top: 0;
        left: 0;
        position: absolute;
        background: ${palette.black3};
        width: 60px;
        height: 25px;
        line-height: 25px;
      }
    }
  }
`
