import React, { useContext } from 'react'
import { Element } from 'react-scroll'
import { FormattedMessage } from 'react-intl'
import Link from 'next/link'
import Flex from '@zcool/flex'
import { palette } from '@zcool/theme'
import { Box, Text } from 'components/ui/base'
import { WelComeBackground } from 'components/ui/background'
import WelcomeHint from 'components/ui/welcome-hint'
import ApplyForm from 'components/forms/apply-form'
import { PLUS_NORMAL_TYPE } from 'shared/symbols'
import { GlobalContext } from 'shared/context'
import { PLUS_CONTENTS } from 'shared/constants'

export default function Welcome() {
  const { locale } = useContext(GlobalContext) || { locale: 'zh' }
  return (
    <WelComeBackground url="//static.hellorf.com/plus/_next/images/KV_welcome.jpg">
      <Flex className="background" valign="center" halign="center">
        <div className="hero-content">
          <FormattedMessage id="page.welcome.headline" tagName="h1" />
          <FormattedMessage id="page.welcome.description" tagName="p" />
          <WelcomeHint />
        </div>
        <span className="image-info">
          <FormattedMessage id="page.editorial.show.id">
            {(msg) => <span className="form-item-label">{msg}</span>}
          </FormattedMessage>
          572804956&nbsp;&nbsp;&nbsp;&nbsp;
          <FormattedMessage id="common.copyright">
            {(msg) => <span className="form-item-label">{msg}</span>}
          </FormattedMessage>
          g-stockstudio
        </span>
      </Flex>
      <Box background={palette.pearl} py={48}>
        <Text as="h2" size={24} color="black3" align="center" block={true}>
          <FormattedMessage id="common.hellorf-plus" />
        </Text>

        <Text size={20} align="center" mt={16} color="pewter" block={true}>
          <FormattedMessage id="page.welcome.sub-headline" />
        </Text>
        <div className="welcome-options">
          {[0, 1, 2, 3].map((item) => (
            <div className="item" key={`welcome-feature-item-${item}`}>
              <img src={`/welcome-feature-${item}.svg`} />
              <FormattedMessage id={`page.welcome.features-${item}`} tagName="p" />
            </div>
          ))}
        </div>
      </Box>
      <Box py={104} align="center" className="welcome-section">
        <div className="welcome-section__text pl64">
          <span className="index">01</span>
          <h3 className="title">
            <FormattedMessage id="page.welcome.features-0" />
          </h3>
          <div className={`bg1 ${locale}`} />
          <div className="feature">
            <FormattedMessage id="page.welcome.features-0-0" tagName="p" />
            <FormattedMessage id="page.welcome.features-0-1" tagName="p" />
            <FormattedMessage id="page.welcome.features-0-2" tagName="p" />
          </div>
        </div>
        <img src="//static.hellorf.com/plus/_next/images/welcome-feature-0.jpg" />
      </Box>
      <Box py={80} align="center" className="welcome-section">
        <img src="//static.hellorf.com/plus/_next/images/welcome-feature-1.jpg" />
        <div className="welcome-section__text">
          <span className="index">02</span>
          <h3 className="title">
            <FormattedMessage id="page.welcome.features-1" />
          </h3>
          <div className="bg2" />
          <div className="feature">
            <FormattedMessage id="page.welcome.features-1-0" tagName="p" />
            <FormattedMessage id="page.welcome.features-1-1" tagName="p" />
          </div>
        </div>
      </Box>
      <Box py={80} align="center" className="welcome-section">
        <div className="welcome-section__text pl64">
          <span className="index">03</span>
          <h3 className="title">
            <FormattedMessage id="page.welcome.features-2" />
          </h3>

          <div className="bg3" />
          <div className="feature">
            <FormattedMessage id="page.welcome.features-2-0" tagName="p" />
            <FormattedMessage id="page.welcome.features-2-1" tagName="p" />
          </div>
        </div>
        <img src="//static.hellorf.com/plus/_next/images/welcome-feature-2.jpg" />
      </Box>
      <Box py={80} align="center" className="welcome-section last">
        <img src="//static.hellorf.com/plus/_next/images/welcome-feature-3.jpg" />
        <div className="welcome-section__text">
          <span className="index">04</span>
          <h3 className="title">
            <FormattedMessage id="page.welcome.features-3" />
          </h3>
          <div className="bg4" />
          <div className="feature">
            <FormattedMessage id="page.welcome.features-3-0" tagName="p" />
            <FormattedMessage id="page.welcome.features-3-1" tagName="p" />
            <FormattedMessage id="page.welcome.features-3-2" tagName="p" />
            <FormattedMessage id="page.welcome.features-3-3" tagName="p" />
          </div>
        </div>
      </Box>
      <Box py={40}>
        <Text as="h4" size={22} mb={40} color="black3" align="center" block={true}>
          <FormattedMessage id="page.welcome.more" />
        </Text>
        <div className="welcome-card">
          {/* 暂时隐藏ip菜单 */}
          {PLUS_CONTENTS.filter((content) => content.id !== 'nav.ip').map(({ id, path }) => (
            <div key={id} className="welcome-card__item">
              <Link href={`/contents${path}`}>
                <a>
                  <img src={`//static.hellorf.com/v2/plus/_next/images${path}-welcome-cover.jpg`} />
                </a>
              </Link>
              <FormattedMessage id={id} tagName="span" />
            </div>
          ))}
        </div>
      </Box>
      <Element name="apply-form">
        <ApplyForm titleColor="white" />
      </Element>
    </WelComeBackground>
  )
}

Welcome.$$type = PLUS_NORMAL_TYPE
